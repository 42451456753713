import { template as template_a47e90788aea4bdd88bb60226981d44b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_a47e90788aea4bdd88bb60226981d44b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
