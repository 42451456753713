import { template as template_5aaec4da1ff64dd982ef9d9d8f125d36 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_5aaec4da1ff64dd982ef9d9d8f125d36(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_5aaec4da1ff64dd982ef9d9d8f125d36(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_5aaec4da1ff64dd982ef9d9d8f125d36(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
