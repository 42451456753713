import { template as template_079e7135ca6c46a1921ece7d8ed3eb70 } from "@ember/template-compiler";
const SidebarSectionMessage = template_079e7135ca6c46a1921ece7d8ed3eb70(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
